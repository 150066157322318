import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import { Button, DecimalInput, Input } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import DraggableImageUploader from "../DraggableImageUpload/DraggableImageUploader";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editPriceGuessingModal.module.scss";

const EditPriceGuessingModal = ({
  handleEditModalClose,
  data,
  handleAdd,
  handleUpdate,
  updateLoading,
}) => {
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      // options: data
      // ? data.options || []
      // : [{ option: "" }, { option: "" }, { option: "" }, { option: "" }],
      module_type: "price_guessing",
    },
  });

  // const { fields, prepend, insert, remove, update } = useFieldArray({
  //   control,
  //   name: "options",
  //   defaultValues: [{ option: "" }],
  // });

  const [rowId, setRowId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const statusList = [
    { label: "Active", value: "active" },
    { label: "InActive", value: "inactive" },
  ];

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (data) {
      console.log(data);
      if (data.status === "active") {
        setSelectedStatus("Active");
      } else {
        setSelectedStatus("InActive");
      }

      setValue("status", data.status);
      // setValue("type", parseInt(data.type));
      setValue("question", data.question);
      setValue("description", data.description);
      setValue("score", data.score);
      setValue("time_limit", data.time_limit);
      setValue("actual_price", data.actual_price);
      setValue("image", data.image_url);
    }
  }, [data, mounted]);

  // const handleSubmit = (e) => {
  //   console.log(userName);
  //   console.log(userEmail);
  //   console.log(password);
  //   console.log(selectedRole);
  // };

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0 black-text">
              {data ? "Edit Price Guessing" : "Add Price Guessing"}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <Form onSubmit={handleSubmit(data ? handleUpdate : handleAdd)}>
              <Row>
                <Col md={6} sm={12}>
                  <Row>
                    <Col md={12}>
                      <p className="m-0 black-text">Question</p>
                      <Controller
                        control={control}
                        name="question"
                        rules={{}}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="text"
                            placeholder="Enter question"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p className="m-0 black-text">Description</p>
                      <Controller
                        control={control}
                        name="description"
                        rules={{}}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="text"
                            placeholder="Enter description"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  {
                    //   <Row>
                    //   <Col md={12}>
                    //     <p className="m-0 black-text">Type</p>
                    //     <Controller
                    //       control={control}
                    //       name="type"
                    //       rules={{}}
                    //       defaultValue={selectedType}
                    //       render={({
                    //         field: { onChange, onBlur, value, ref },
                    //       }) => (
                    //         <SelectFieldComponent
                    //           firstOption={
                    //             _.isEmpty(selectedType)
                    //               ? "Select Type"
                    //               : selectedType
                    //           }
                    //           value={value}
                    //           optionsList={questionType}
                    //           onSelectChange={(value) => {
                    //             const strToInt = parseInt(value);
                    //             console.log(strToInt);
                    //             onChange(strToInt);
                    //           }}
                    //         />
                    //       )}
                    //     />
                    //   </Col>
                    // </Row>
                  }
                  <Row>
                    <Col md={12}>
                      <p className="m-0 black-text">Score</p>
                      <Controller
                        control={control}
                        name="score"
                        rules={{}}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <DecimalInput
                            classes="mt-2 mb-3"
                            placeholder="Enter score"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p className="m-0 black-text">Actual Price</p>
                      <Controller
                        control={control}
                        name="actual_price"
                        rules={{}}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <DecimalInput
                            classes="mt-2 mb-3"
                            placeholder="Enter actual price"
                            value={value}
                            onChange={(value) => {
                              onChange(value);
                            }}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <p className="m-0 black-text">Status</p>
                      <Controller
                        control={control}
                        name="status"
                        rules={{}}
                        defaultValue={selectedStatus}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <SelectFieldComponent
                            firstOption={
                              _.isEmpty(selectedStatus)
                                ? "Select Status"
                                : selectedStatus
                            }
                            optionsList={statusList}
                            onSelectChange={(value) => {
                              // const strToInt = parseInt(value);
                              // console.log(strToInt);

                              onChange(value);
                            }}
                          />
                        )}
                      />
                    </Col>

                    <Col lg={6}>
                      <p className="m-0 black-text">Time Limit</p>
                      <Controller
                        control={control}
                        name="time_limit"
                        rules={{}}
                        defaultValue={30}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2"
                            type="number"
                            placeholder="Enter time left"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                      <span className="gray-text fs-8">
                        (in seconds) by default 30 seconds
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Button
                        classes={`${cssStyles.cardBtn} mt-4 ${
                          updateLoading ? "bg-dark-subtle" : ""
                        }`}
                        text={
                          updateLoading ? (
                            <BeatLoader size={10} />
                          ) : data ? (
                            "Update"
                          ) : (
                            "Save"
                          )
                        }
                        // onClick={handleSubmit}
                        type="Submit"
                      ></Button>
                    </Col>
                  </Row>
                </Col>
                <Col md={6} sm={12}>
                  <Row>
                    <Col md={12}>
                      {/* <p className="m-0 black-text">Upload Image</p> */}
                      <Controller
                        control={control}
                        name="image"
                        rules={{}}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <DraggableImageUploader
                            key={1}
                            mediaImage={value}
                            setMediaImage={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  {
                    //   data.image_url !== "" ? (
                    //   <img
                    //     alt=""
                    //     src={data.image_url}
                    //     className="rounded-4"
                    //     // width={"150px"}
                    //     // height={"100px"}
                    //   />
                    // ) : (
                    //   ""
                    // )
                  }
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditPriceGuessingModal;
