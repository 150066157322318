import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Button, SubTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Table from "../../Components/Table/Table";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/mcq_management.module.scss";

// import { ToastContainer, toast } from "react-toastify";

//
import { Col, Row } from "react-bootstrap";
import { FaUserEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
import { ShimmerTable } from "react-shimmer-effects";
import EditPromoCodeModal from "../../Components/Modals/EditPromoCodeModal";
import {
  useAddPromoCodeMutation,
  useDeletePromoCodeMutation,
  useGetPrizeRulesQuery,
  useGetPromoCodesQuery,
  useUpdatePromoCodeMutation,
} from "../../Data/services/promoCodeApi";
import { convertToLocalTime } from "../../Helper/time";
const PromoCodesManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const {
    data: prizeRules,
    isSuccess: prizeRulesSuccess,
    isLoading: prizeRulesLoading,
    isFetching: prizeRulesFetching,
    refetch: prizeRulesRefetch,
  } = useGetPrizeRulesQuery({
    refetchOnMountOrArgChange: true,
    // skipToken: editModal,
  });
  // console.log(prizeRules);

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetPromoCodesQuery({ refetchOnMountOrArgChange: true });
  // console.log(data);

  var promoData = useSelector((state) => state.promoCode.promoCode);
  // console.log(`promoData = ${promoData}`);
  useEffect(() => {
    setRowData(promoData);
  }, [promoData]);

  const price_rules_data = useSelector((state) => state.promoCode.prizeRules);

  // const dummyData = [
  //   {
  //     id: 2,
  //     type: "games",
  //     code: "ceheoe3",
  //     description: null,
  //     discount_type: "check",
  //     discount_value: 30.4,
  //     start_date: "2024-09-10T10:00:00.000Z",
  //     end_at: "2024-09-10T11:00:00.000Z",
  //     usage_limit: 100,
  //     used_count: 0,
  //     status: "active",
  //     createdAt: "2024-10-03T08:17:56.000Z",
  //     updatedAt: "2024-10-03T08:17:56.000Z",
  //     deletedAt: null,
  //   },
  //   {
  //     id: 3,
  //     type: "games",
  //     code: "728BCFES3K5F",
  //     description: null,
  //     discount_type: "percentage",
  //     discount_value: 30.4,
  //     start_date: "2024-09-10T15:00:00.000Z",
  //     end_at: "2024-09-10T16:00:00.000Z",
  //     usage_limit: 100,
  //     used_count: 0,
  //     status: "active",
  //     createdAt: "2024-10-03T08:18:25.000Z",
  //     updatedAt: "2024-10-03T08:18:25.000Z",
  //     deletedAt: null,
  //   },
  //   {
  //     id: 4,
  //     type: "games",
  //     code: "728BCFES3K5F",
  //     description: null,
  //     discount_type: "percentage",
  //     discount_value: 30.4,
  //     start_date: "2024-09-10T15:00:00.000Z",
  //     end_at: "2024-09-10T16:00:00.000Z",
  //     usage_limit: 100,
  //     used_count: 0,
  //     status: "active",
  //     createdAt: "2024-10-03T08:18:40.000Z",
  //     updatedAt: "2024-10-03T08:18:40.000Z",
  //     deletedAt: null,
  //   },
  //   {
  //     id: 5,
  //     type: "games",
  //     code: "728BCFES3K5F",
  //     description: null,
  //     discount_type: "percentage",
  //     discount_value: 30.4,
  //     start_date: "2024-09-10T15:00:00.000Z",
  //     end_at: "2024-09-10T16:00:00.000Z",
  //     usage_limit: 100,
  //     used_count: 0,
  //     status: "active",
  //     createdAt: "2024-10-03T08:23:48.000Z",
  //     updatedAt: "2024-10-03T08:23:48.000Z",
  //     deletedAt: null,
  //   },
  // ];

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditedData("");
  };

  const handleAddUser = () => {
    // toast("Here is your toast.");
    // console.log("toast");
    setEditModal(true);
  };

  const [deletePromo, { isLoading: isDeleting }] = useDeletePromoCodeMutation();

  const deleteData = async () => {
    // console.log(editedData.id);

    const id = editedData.id;

    try {
      const response = await deletePromo(id);

      console.log(response);

      if (response.data.code === 200) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(response.data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  const [addPromo] = useAddPromoCodeMutation();

  const handleAdd = async (data) => {
    console.log(data);

    try {
      const response = await addPromo(data);

      console.log(response);

      if (response.data.code === 200) {
        await refetch();
        handleEditModalClose();
        SuccessToast(response.data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  const [updatePromo, { isLoading: updateLoading }] =
    useUpdatePromoCodeMutation();

  const handleUpdate = async (formData) => {
    const id = editedData.id;

    console.log(formData);

    try {
      const { data } = await updatePromo({
        modifiedData: formData,
        id: editedData.id,
      });
      console.log(data);

      if (data.code === 200) {
        await refetch();
        handleEditModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  function indication() {
    // return something here
    // return 'Table is empty';
    return <ShimmerTable row={5} col={4} />;
  }

  const columns = [
    {
      dataField: "code",
      text: "Promo Code",
      sort: true,
      align: "center",
      headerStyle: {
        width: "150px",
        textAlign: "center",
      },
      formatter: (cellContent, row) => {
        // console.log(row.round_one_start_at);
        if (row.id) {
          return <div className={`p-2 rounded-5 bg-warning`}>{row.code}</div>;
        }
      },
    },
    {
      dataField: "type",
      text: "Type",
      align: "center",
      sort: true,
      headerStyle: {
        width: "100px",
        textAlign: "center",
      },
    },
    {
      dataField: "discount_type",
      text: "Dis...Type",
      align: "center",
      sort: true,
      headerStyle: {
        width: "120px",
        textAlign: "center",
      },
    },
    {
      dataField: "discount_value",
      text: "Amount",
      align: "center",
      sort: true,
      headerStyle: {
        width: "100px",
        textAlign: "center",
      },
    },
    {
      dataField: "start_date",
      text: "Start at",
      sort: true,
      align: "center",
      headerStyle: {
        width: "100px",
        textAlign: "center",
      },
      formatter: (cellContent, row) => {
        // console.log(row.round_one_start_at);
        if (row.id) {
          return convertToLocalTime(row.round_one_start_at);
        }
      },
    },
    {
      dataField: "ends_at",
      text: "End at",
      sort: true,
      align: "center",
      headerStyle: {
        width: "100px",
        textAlign: "center",
      },
      formatter: (cellContent, row) => {
        // console.log(row.round_one_start_at);
        if (row.id) {
          return convertToLocalTime(row.round_one_end_at);
        }
      },
    },
    {
      dataField: "usage_limit",
      text: "Limit",
      sort: true,
      align: "center",
      headerStyle: {
        width: "80px",
        textAlign: "center",
      },
    },
    {
      dataField: "used_count",
      text: "Count",
      sort: true,
      align: "center",
      headerStyle: {
        width: "80px",
        textAlign: "center",
      },
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "80px",
      },
      formatter: (cellContent, row) => {
        if (row.status === "active") {
          return <div className={` ${styles.active}`}> Active </div>;
        } else {
          return <div className={`${styles.block}`}>InActive</div>;
        }
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "180px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.id) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              {
                //   <div
                //   className={`${styles.btnBg} d-flex justify-content-center align-items-center black-text rounded-3 mx-2 p-2 `}
                //   role="button"
                //   onClick={() => {
                //     setEditedData(row);
                //     handleEditModalOpen(true);
                //   }}
                // >
                //   <span
                //     className={`align-items-center d-flex justify-content-center pe-1 `}
                //   >
                //     <MdRemoveRedEye />
                //   </span>
                //   <span>View</span>
                // </div>
              }
              {
                <div
                  className={`${styles.btnBg} d-flex justify-content-center align-items-center black-text rounded-3 mx-2 p-2 `}
                  role="button"
                  onClick={() => {
                    setEditedData(row);
                    handleEditModalOpen(true);
                  }}
                >
                  <span
                    className={`align-items-center d-flex justify-content-center pe-1 `}
                  >
                    <FaUserEdit />
                  </span>
                  <span>Edit</span>
                </div>
              }
              <div
                className="d-flex justify-content-center align-items-center bg-danger white-text rounded-3 p-2 mx-2 "
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleDeleteModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <MdDeleteForever />
                </span>
                <span>Delete</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
          isDeleting={isDeleting}
        />
      ) : (
        ""
      )}

      {editModal ? (
        <EditPromoCodeModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
          handleAdd={handleAdd}
          handleUpdate={handleUpdate}
          updateLoading={updateLoading}
          priceRuleData={price_rules_data}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Promo Codes"} classes="black-text" />
        </div>
        <Row>
          <Col md={12}>
            {
              <Button
                classes={`${styles.cardBtn} `}
                text="Set Promo Code"
                onClick={handleAddUser}
              ></Button>
            }
          </Col>
        </Row>
      </div>

      <div className={styles.mainTable}>
        {
          <Table
            data={rowData || []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default PromoCodesManagementScreen;
