import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import { Button, Input } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editPromoCodeModal.module.scss";

const EditPromoCodeModal = ({
  handleEditModalClose,
  data,
  handleAdd,
  handleUpdate,
  updateLoading,
  priceRuleData,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      description: "",
      code: "",
      type: "",
      status: "",
    },
  });

  // console.log(data);

  const priceRuleList = priceRuleData.map((rule) => ({
    label: rule.title,
    value: rule.title,
  }));

  const [rowId, setRowId] = useState(null);
  const [selectedRule, setSelectedRule] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const typeList = [
    { value: "games", label: "Games" },
    { value: "survey", label: "Survey" },
  ];

  // const discount_type = [
  //   { value: "games", label: "Games" },
  //   { value: "survey", label: "Survey" },
  // ];

  const statusList = [
    { label: "Active", value: "active" },
    { label: "In Active", value: "inactive" },
  ];

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  // const price_rule_data = [
  //   {
  //     id: 20850341642513,
  //     price_rule_id: 1449201271057,
  //     code: "SPJFSMTXNZAB",
  //     usage_count: 0,
  //     created_at: "2024-10-02T02:00:59-04:00",
  //     updated_at: "2024-10-02T02:00:59-04:00",
  //   },
  // ];

  // const dummyData = [
  //   {
  //     id: 20850341642513,
  //     price_rule_id: 1449201271057,
  //     code: "SPJFSMTXNZAB",
  //     usage_count: 0,
  //     created_at: "2024-10-02T02:00:59-04:00",
  //     updated_at: "2024-10-02T02:00:59-04:00",
  //   },
  // ];

  useEffect(() => {
    console.log(data);
    if (data) {
      setRowId(data.id);
      setValue("description", data.description || "");
      setValue("code", data.code || "");
      setValue("type", data.type || "");
      setSelectedType(data.type === "games" ? "Game" : "Survey" || "");
      setValue("status", data.status || "");
      setSelectedStatus(
        data.status === "active" ? "Active" : "In Active" || ""
      );
      // setSelectedRule(priceRuleData.find((rule) => rule.title === data.code));
    }
  }, [data, mounted]);

  const handleAddData = async (data) => {
    data.discount_type = selectedRule.value_type;
    data.discount_value = Number(selectedRule.value);
    data.start_date = selectedRule.starts_at;
    data.end_at = selectedRule.ends_at;
    data.usage_limit = Number(selectedRule.usage_limit);

    // console.log(data);
    handleAdd(data);
  };

  const handleUpdateData = async (data) => {
    // data.discount_type = selectedRule.value_type;
    // data.discount_value = Number(selectedRule.value);
    // data.start_date = selectedRule.starts_at;
    // data.end_at = selectedRule.ends_at;
    // data.usage_limit = Number(selectedRule.usage_limit);

    // console.log(rowId);
    console.log(data);
    handleUpdate(data);
  };

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0 black-text">
              {data ? "Update Promo Status" : "Set Promo Code"}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            {
              // <h6>
              //   Promo Code: <b> {dummyData[0].code} </b>{" "}
              // </h6>
              // <h6>
              //   Counts: <b> {dummyData[0].usage_count} </b>{" "}
              // </h6>
            }
            <Form
              onSubmit={handleSubmit(data ? handleUpdateData : handleAddData)}
            >
              <Row>
                <Col md={6} sm={12}>
                  {data ? (
                    <p className="fs-5 fw-bold rounded-5 p-2 bg-warning text-center">
                      {data.code}
                    </p>
                  ) : (
                    <Row>
                      <Col>
                        <p className="m-0 black-text">Select Price Rule</p>
                        <Controller
                          control={control}
                          name="code"
                          rules={{
                            required: "Code required.",
                          }}
                          // defaultValue={selectedRule}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <SelectFieldComponent
                              firstOption={
                                _.isEmpty(selectedRule)
                                  ? "Select Rule"
                                  : selectedRule.title
                              }
                              optionsList={priceRuleList}
                              onSelectChange={(value) => {
                                // console.log(value);
                                const selectedPriceRule = priceRuleData.find(
                                  (rule) => rule.title === value
                                );
                                if (selectedPriceRule) {
                                  // console.log(selectedPriceRule);

                                  setSelectedRule(selectedPriceRule);
                                  onChange(value);
                                }
                              }}
                            />
                          )}
                        />
                        {errors?.code?.message ? (
                          <p className="m-0 text-danger text-center">
                            {errors?.code?.message}
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <p className="m-0 black-text">Select Type</p>
                      <Controller
                        control={control}
                        name="type"
                        rules={{
                          required: "Status required.",
                        }}
                        defaultValue={selectedType}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <SelectFieldComponent
                            firstOption={
                              _.isEmpty(selectedType)
                                ? "Select Type"
                                : selectedType
                            }
                            optionsList={typeList}
                            onSelectChange={(value) => {
                              console.log(value);
                              onChange(value);
                            }}
                          />
                        )}
                      />
                      {errors?.type?.message ? (
                        <p className="m-0 text-danger text-center">
                          {errors?.type?.message}
                        </p>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="m-0 black-text">Status</p>
                      <Controller
                        control={control}
                        name="status"
                        rules={{
                          required: "Status required.",
                        }}
                        defaultValue={selectedStatus}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <SelectFieldComponent
                            firstOption={
                              _.isEmpty(selectedStatus)
                                ? "Select Status"
                                : selectedStatus
                            }
                            optionsList={statusList}
                            onSelectChange={(value) => {
                              // const strToInt = parseInt(value);
                              // console.log(strToInt);
                              // onChange(strToInt);
                              onChange(value);
                            }}
                          />
                        )}
                      />
                      {errors?.status?.message ? (
                        <p className="m-0 text-danger text-center">
                          {errors?.status?.message}
                        </p>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p className="m-0 black-text">Description</p>
                      <Controller
                        control={control}
                        name="description"
                        rules={{}}
                        defaultValue={""}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="text"
                            placeholder="Enter description"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6} sm={12}>
                  {
                    // <Row>
                    //   <Col md={6}>
                    //     {
                    //       // <p className="m-0 black-text">Round Sessions</p>
                    //     }
                    //     {/** custom date time  */}
                    //     <p className="m-0 black-text">Round 1 Start at </p>
                    //     <CustomDateTimePicker
                    //       classes="mt-2 mb-3"
                    //       // value={round1Start}
                    //       // onChange={handleRound1Start}
                    //     />
                    //   </Col>
                    //   <Col md={6}>
                    //     {
                    //       // <p className="m-0 black-text">Round Sessions</p>
                    //     }
                    //     {/** custom date time  */}
                    //     <p className="m-0 black-text">Round 1 End at </p>
                    //     <CustomDateTimePicker
                    //       classes="mt-2 mb-3"
                    //       // value={round1End}
                    //       // onChange={handleRound1End}
                    //     />
                    //   </Col>
                    // </Row>
                  }
                  {selectedRule !== null ? (
                    <div>
                      <p>
                        <b> Discount Type: </b> {selectedRule.value_type}
                      </p>
                      <p>
                        <b> Discounted Value: </b> {selectedRule.value}
                      </p>
                      <p>
                        <b> Start at: </b> {selectedRule.starts_at}
                      </p>
                      <p>
                        <b> End at: </b> {selectedRule.ends_at}
                      </p>
                      <p>
                        <b> Usage Limit: </b> {selectedRule.usage_limit}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Button
                    classes={`${cssStyles.cardBtn} mt-4 ${
                      updateLoading ? "bg-dark-subtle" : ""
                    }`}
                    text={
                      updateLoading ? (
                        <BeatLoader size={10} />
                      ) : data ? (
                        "Update"
                      ) : (
                        "Save"
                      )
                    }
                    // onClick={handleSubmit}
                    type="Submit"
                  ></Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditPromoCodeModal;
