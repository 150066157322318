import dayjs from "dayjs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import { convertToUtc } from "../../Helper/time";
import { Button, Input } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import CustomDateTimePicker from "../CustomDateTimePicker/CustomDateTimePicker";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editMcqModal.module.scss";

const EditSessionModal = ({
  handleEditModalClose,
  data,
  handleAdd,
  handleUpdate,
  updateLoading,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      // options: data
      // ? data.options || []
      // : [{ option: "" }, { option: "" }, { option: "" }, { option: "" }],
      // type: 1,
    },
  });

  const [rowId, setRowId] = useState("");
  const [userSlug, setUserSlug] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const [round1Start, setRound1Start] = useState("");
  const [round1End, setRound1End] = useState("");
  const [round2Start, setRound2Start] = useState("");
  const [round2End, setRound2End] = useState("");
  const [round3Start, setRound3Start] = useState("");
  const [round3End, setRound3End] = useState("");
  const [round4Start, setRound4Start] = useState("");
  const [round4End, setRound4End] = useState("");

  const handleRound1Start = (data) => {
    // console.log(data.$d);
    const utc = convertToUtc(data.$d);
    // console.log(utc);

    setRound1Start(utc);
  };
  const handleRound1End = (data) => {
    // console.log(data);
    const utc = convertToUtc(data.$d);
    setRound1End(utc);
  };
  const handleRound2Start = (data) => {
    // console.log(data);
    const utc = convertToUtc(data.$d);
    setRound2Start(utc);
  };
  const handleRound2End = (data) => {
    // console.log(data);
    const utc = convertToUtc(data.$d);
    setRound2End(utc);
  };
  const handleRound3Start = (data) => {
    // console.log(data);
    const utc = convertToUtc(data.$d);
    setRound3Start(utc);
  };
  const handleRound3End = (data) => {
    // console.log(data);
    const utc = convertToUtc(data.$d);
    setRound3End(utc);
  };
  const handleRound4Start = (data) => {
    // console.log(data);
    const utc = convertToUtc(data.$d);
    setRound4Start(utc);
  };
  const handleRound4End = (data) => {
    // console.log(data);
    const utc = convertToUtc(data.$d);
    setRound4End(utc);
  };

  const statusList = [
    { label: "Active", value: "active" },
    { label: "In Active", value: "inactive" },
  ];

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (data) {
      // console.log(data);

      setRowId(data.id);
      setValue("status", data.status || "");
      setSelectedStatus(
        data.status === "active" ? "Active" : "In Active" || ""
      );
      setValue("name", data.name);
      setValue("description", data.description);

      // console.log(data.round_one_start_at !== null);

      setRound1Start(
        data.round_one_start_at !== null ? dayjs(data.round_one_start_at) : ""
      );
      setRound1End(
        data.round_one_end_at !== null ? dayjs(data.round_one_end_at) : ""
      );
      setRound2Start(
        data.round_two_start_at !== null ? dayjs(data.round_two_start_at) : ""
      );
      setRound2End(
        data.round_two_end_at !== null ? dayjs(data.round_two_end_at) : ""
      );
      setRound3Start(
        data.round_three_start_at !== null
          ? dayjs(data.round_three_start_at)
          : ""
      );
      setRound3End(
        data.round_three_end_at !== null ? dayjs(data.round_three_end_at) : ""
      );
      setRound4Start(
        data.round_four_start_at !== null ? dayjs(data.round_four_start_at) : ""
      );
      setRound4End(
        data.round_four_end_at !== null ? dayjs(data.round_four_end_at) : ""
      );
    }
  }, [data, mounted]);

  // const handleSubmit = (e) => {
  //   console.log(userName);
  //   console.log(userEmail);
  //   console.log(password);
  //   console.log(selectedRole);
  // };

  const handleAddDateTimeInData = async (formData) => {
    console.log(formData);
    console.log(round1Start === "");
    if (data) {
      
      if (round1Start !== "") {
        formData.round_one_start_at = convertToUtc(round1Start.$d);
      }
      if (round1End !== "") {
        formData.round_one_end_at = convertToUtc(round1End.$d);
      }
      if (round2Start !== "") {
        formData.round_two_start_at = convertToUtc(round2Start.$d);
      }
      if (round2End !== "") {
        formData.round_two_end_at = convertToUtc(round2End.$d);
      }
      if (round3Start !== "") {
        formData.round_three_start_at = convertToUtc(round3Start.$d);
      }
      if (round3End !== "") {
        formData.round_three_end_at = convertToUtc(round3End.$d);
      }
      if (round4Start !== "") {
        formData.round_four_start_at = convertToUtc(round4Start.$d);
      }
      if (round4End !== "") {
        formData.round_four_end_at = convertToUtc(round4End.$d);
      }

      handleUpdate(formData);
    } else {
      if (round1Start !== "") {
        formData.round_one_start_at = round1Start;
      }
      if (round1End !== "") {
        formData.round_one_end_at = round1End;
      }
      if (round2Start !== "") {
        formData.round_two_start_at = round2Start;
      }
      if (round2End !== "") {
        formData.round_two_end_at = round2End;
      }
      if (round3Start !== "") {
        formData.round_three_start_at = round3Start;
      }
      if (round3End !== "") {
        formData.round_three_end_at = round3End;
      }
      if (round4Start !== "") {
        formData.round_four_start_at = round4Start;
      }
      if (round4End !== "") {
        formData.round_four_end_at = round4End;
      }

      handleAdd(formData);
    }
  };

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0 black-text">
              {data ? "Edit Session" : "Add Session"}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <Form onSubmit={handleSubmit(handleAddDateTimeInData)}>
              <Row>
                <Col md={6} sm={12}>
                  <Row>
                    <Col md={12}>
                      <p className="m-0 black-text">Session Name</p>
                      <Controller
                        control={control}
                        name="name"
                        rules={{
                          required: "Session name is required.",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="text"
                            placeholder="Enter question"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />

                      {errors?.name?.message ? (
                        <p className="m-0 text-danger text-center">
                          {errors?.name?.message}
                        </p>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p className="m-0 black-text">Description</p>
                      <Controller
                        control={control}
                        name="description"
                        rules={{
                          required: "Description is required.",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="text"
                            placeholder="Enter description"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                      {errors?.description?.message ? (
                        <p className="m-0 text-danger text-center">
                          {errors?.description?.message}
                        </p>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <p className="m-0 black-text">Status</p>
                      <Controller
                        control={control}
                        name="status"
                        rules={{
                          required: "Status is required.",
                        }}
                        defaultValue={selectedStatus}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <SelectFieldComponent
                            firstOption={
                              _.isEmpty(selectedStatus)
                                ? "Select Status"
                                : selectedStatus
                            }
                            optionsList={statusList}
                            onSelectChange={(value) => {
                              // const strToInt = parseInt(value);
                              // console.log(strToInt);
                              // onChange(strToInt);
                              onChange(value);
                            }}
                          />
                        )}
                      />

                      {errors?.status?.message ? (
                        <p className="m-0 text-danger text-center">
                          {errors?.status?.message}
                        </p>
                      ) : (
                        ""
                      )}
                    </Col>

                    <Col lg={6}>
                      {
                        // <p className="m-0 black-text">Time Limit</p>
                        // <Controller
                        //   control={control}
                        //   name="time_limit"
                        //   rules={{}}
                        //   defaultValue={30}
                        //   render={({
                        //     field: { onChange, onBlur, value, ref },
                        //   }) => (
                        //     <Input
                        //       classes="mt-2"
                        //       type="number"
                        //       placeholder="Enter time left"
                        //       value={value}
                        //       onChange={onChange}
                        //     />
                        //   )}
                        // />
                        // <span className="gray-text fs-8">
                        //   (in seconds) by default 30 seconds
                        // </span>
                      }
                    </Col>
                  </Row>
                </Col>
                <Col md={6} sm={12}>
                  <Row>
                    <Col md={6}>
                      {
                        // <p className="m-0 black-text">Round Sessions</p>
                      }
                      {/** custom date time  */}
                      <p className="m-0 black-text">Round 1 Start at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round1Start}
                        onChange={handleRound1Start}
                      />
                    </Col>
                    <Col md={6}>
                      {
                        // <p className="m-0 black-text">Round Sessions</p>
                      }
                      {/** custom date time  */}
                      <p className="m-0 black-text">Round 1 End at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round1End}
                        onChange={handleRound1End}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      {
                        // <p className="m-0 black-text">Round Sessions</p>
                      }
                      {/** custom date time  */}
                      <p className="m-0 black-text">Round 2 Start at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round2Start}
                        onChange={handleRound2Start}
                      />
                    </Col>
                    <Col md={6}>
                      {
                        // <p className="m-0 black-text">Round Sessions</p>
                      }
                      {/** custom date time  */}
                      <p className="m-0 black-text">Round 2 End at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round2End}
                        onChange={handleRound2End}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      {
                        // <p className="m-0 black-text">Round Sessions</p>
                      }
                      {/** custom date time  */}
                      <p className="m-0 black-text">Round 3 Start at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round3Start}
                        onChange={handleRound3Start}
                      />
                    </Col>
                    <Col md={6}>
                      {
                        // <p className="m-0 black-text">Round Sessions</p>
                      }
                      {/** custom date time  */}
                      <p className="m-0 black-text">Round 3 End at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round3End}
                        onChange={handleRound3End}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      {
                        // <p className="m-0 black-text">Round Sessions</p>
                      }
                      {/** custom date time  */}
                      <p className="m-0 black-text">Round 4 Start at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round4Start}
                        onChange={handleRound4Start}
                      />
                    </Col>
                    <Col md={6}>
                      {
                        // <p className="m-0 black-text">Round Sessions</p>
                      }
                      {/** custom date time  */}
                      <p className="m-0 black-text">Round 4 End at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round4End}
                        onChange={handleRound4End}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Button
                    classes={`${cssStyles.cardBtn} mt-4 ${
                      updateLoading ? "bg-dark-subtle" : ""
                    }`}
                    text={
                      updateLoading ? (
                        <BeatLoader size={10} />
                      ) : data ? (
                        "Update"
                      ) : (
                        "Save"
                      )
                    }
                    // onClick={handleSubmit}
                    type="Submit"
                  ></Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditSessionModal;
