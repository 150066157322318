import { message } from "antd";
import Dragger from "antd/es/upload/Dragger";
import _ from "lodash";
import React from "react";
import { FaRegImage } from "react-icons/fa6";
import Toast from "../../Hooks/useToast";

const DraggableImageUploader = ({ mediaImage, setMediaImage }) => {
  // const handleFileChange = (info) => {
  //     setMediaImage(info.file.originFileObj);
  // }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJpgOrPng) {
      Toast("You can only upload JPG/PNG file!", "error", false);
    } else if (!isLt2M) {
      Toast("Image must smaller than 2MB!", "error", false);
    }
    return isJpgOrPng && isLt2M;
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const props = {
    name: "file",
    multiple: true,
    showUploadList: true,
    accept: "image/jpeg,image/png,image/png",
    value: !_.isObject(mediaImage)
      ? mediaImage
      : URL.createObjectURL(mediaImage),
    beforeUpload: beforeUpload, // assuming beforeUpload is a function defined elsewhere
    // onChange: handleFileChange, // assuming handleFileChange is a function defined elsewhere
    customRequest: dummyRequest, // assuming dummyRequest is a function defined elsewhere
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
        setMediaImage(info.file.originFileObj);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      setMediaImage("");
    },
  };

  return (
    <div className="d-flex flex-column mx-2 mb-4 text-center">
      <div style={{ height: "auto", paddingBottom: "8px" }}>
        <Dragger {...props}>
          {!_.isEmpty(mediaImage) ? (
            <img
              src={
                !_.isObject(mediaImage)
                  ? mediaImage
                  : URL.createObjectURL(mediaImage)
              }
              alt="avatar"
              style={{ width: "100%", height: "auto" }}
              className="img-fluid rounded-3"
            />
          ) : (
            <div>
              <p className="ant-upload-drag-icon text-black">
                <FaRegImage size={20} />
              </p>
              <p className="ant-upload-text text-black">Add Image</p>
              <p className="ant-upload-hint text-black"> or drag and drop </p>
            </div>
          )}
        </Dragger>
      </div>
    </div>
  );
};

export default DraggableImageUploader;
