import { createSlice } from "@reduxjs/toolkit";
import { promoCodeApi } from "../services/promoCodeApi";

const initialState = {
  promoCode: null,
  prizeRules: null,
};

export const promoCodeSlice = createSlice({
  name: "promoCode",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        promoCodeApi.endpoints.getPromoCodes.matchFulfilled,
        (state, { payload }) => {
          state.promoCode = payload.data;
        }
      )
      .addMatcher(
        promoCodeApi.endpoints.getPromoCodes.matchRejected,
        (state) => {
          state.promoCode = null;
        }
      )
      .addMatcher(
        promoCodeApi.endpoints.getPrizeRules.matchFulfilled,
        (state, { payload }) => {
          state.prizeRules = payload.data.price_rules;
        }
      )
      .addMatcher(
        promoCodeApi.endpoints.getPrizeRules.matchRejected,
        (state) => {
          state.prizeRules = null;
        }
      );
  },
});

export const { setDashboardInfo, unsetDashboardInfo } = promoCodeSlice.actions;

export default promoCodeSlice.reducer;
