import React, { useState } from "react";

import { ACCOUNT_ENUM } from "./Route";

import Login from "./Login";
import ForgotPassword from "./ForgotPassword";



export default function Account() {
    const [state, setState] = useState(ACCOUNT_ENUM.LOGIN)

    switch (state) {
        case ACCOUNT_ENUM.LOGIN:
            return <Login setPage={setState} />
        case ACCOUNT_ENUM.FORGOT_PASSWORD:
            return <ForgotPassword setPage={setState} />
        default:
            return <Login />

    }
}