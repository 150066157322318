import React from "react";
import { Navigate } from "react-router-dom";
import { WEB_ROUTES } from "../../constants";
import { getToken } from "../../Data/services/localStorageService";
import { baseRoute } from "../../Helper";

export default function PublicRoute({ children }) {
  // const token = false;
  const token = getToken();

  return token ? (
    <Navigate to={baseRoute(WEB_ROUTES.DASHBOARD)} replace />
  ) : (
    children
  );
}
