import React, { useState } from 'react';
import { ROUTES } from './constants';
import Dashboard from './Dashboard';



export default function Index() {
    const [page, setPage] = useState(ROUTES.DETAILS);


    switch (page) {
        case ROUTES.DETAILS:
            return <Dashboard setPage={setPage} />

        default:
            return <Dashboard setPage={setPage} />
    }


}

